import '../scss/main.scss';

interface PluginInitializer {
    (shadow_root: ShadowRoot): void;
}

interface PluginLoader {
    addPlugin(name: string, initializer: PluginInitializer): void;
    loadPlugin(containerId: string, pluginName: string): void;
}

declare const PluginLoader: PluginLoader;

PluginLoader.addPlugin('booking-portal', (shadow_root: ShadowRoot): void => {
    // Query the dropdown toggle and dropdown content.
    const toggle = shadow_root.querySelector('.dropdown-toggle') as HTMLElement | null;
    if (!toggle) {
        console.error('booking-portal: Dropdown toggle element not found.');
        return;
    }
    const content = shadow_root.querySelector('.dropdown-content') as HTMLElement | null;
    if (!content) {
        console.error('booking-portal: Dropdown content element not found.');
        return;
    }

    // Ensure the dropdown content is hidden initially.
    content.style.display = 'none';
    content.style.position = 'absolute';

    // Toggle the dropdown on click.
    toggle.addEventListener('click', (e: Event): void => {
        e.stopPropagation(); // Prevent the click from bubbling up to document.
        if (content.style.display === 'none' || content.style.display === '') {
            content.style.display = 'block';
            // Position the dropdown content directly below the toggle.
            const top = toggle.offsetTop + toggle.offsetHeight;
            const left = toggle.offsetLeft;
            content.style.top = `${top}px`;
            content.style.left = `${left}px`;
        } else {
            content.style.display = 'none';
        }
    });

    // Close the dropdown when clicking anywhere outside the dropdown toggle or content.
    document.addEventListener('click', (e: Event): void => {
        // Use composedPath to account for clicks from within shadow DOM.
        const path = e.composedPath();
        if (!path.includes(toggle) && !path.includes(content)) {
            content.style.display = 'none';
        }
    });

    // Plus/Minus Button Functionality.
    shadow_root.querySelectorAll('.input-number-plus').forEach((btn: Element): void => {
        btn.addEventListener('click', (e: Event): void => {
            e.preventDefault();
            const parent = (btn as HTMLElement).parentElement;
            if (parent) {
                const input = parent.querySelector('input') as HTMLInputElement | null;
                if (input) {
                    const max = parseInt(input.getAttribute('data-max') || '0');
                    const current = parseInt(input.value);
                    if (current < max) {
                        input.value = String(current + 1);
                        updateGuestSummary();
                    }
                }
            }
        });
    });

    shadow_root.querySelectorAll('.input-number-min').forEach((btn: Element): void => {
        btn.addEventListener('click', (e: Event): void => {
            e.preventDefault();
            const parent = (btn as HTMLElement).parentElement;
            if (parent) {
                const input = parent.querySelector('input') as HTMLInputElement | null;
                if (input) {
                    const min = parseInt(input.getAttribute('data-min') || '0');
                    const current = parseInt(input.value);
                    if (current > min) {
                        input.value = String(current - 1);
                        updateGuestSummary();
                    }
                }
            }
        });
    });

    // Update guest summary (combining adults and seniors; summing all children).
    function updateGuestSummary(): void {
        const adults_input = shadow_root.querySelector('input[name="adults"]') as HTMLInputElement | null;
        const seniors_input = shadow_root.querySelector('input[name="seniors"]') as HTMLInputElement | null;
        const children_a_input = shadow_root.querySelector('input[name="children_a"]') as HTMLInputElement | null;
        const children_b_input = shadow_root.querySelector('input[name="children_b"]') as HTMLInputElement | null;
        const children_c_input = shadow_root.querySelector('input[name="children_c"]') as HTMLInputElement | null;

        if (adults_input && seniors_input && children_a_input && children_b_input && children_c_input) {
            const adults = parseInt(adults_input.value) || 0;
            const seniors = parseInt(seniors_input.value) || 0;
            const children =
                (parseInt(children_a_input.value) +
                    parseInt(children_b_input.value) +
                    parseInt(children_c_input.value)) || 0;
            const total_adults = adults + seniors;
            const guest_summary_element = shadow_root.getElementById('guestSummary');

            if (guest_summary_element) {
                const spans = guest_summary_element.getElementsByTagName('span');
                if (spans.length >= 2) {
                    spans[0].textContent = `${total_adults}`;
                    spans[1].textContent = `${children}`;
                }
            }
        }
    }

    updateGuestSummary();

    function update_total_price(): void {
        let total: number = 0;
        // Select all article checkboxes within the articles container.
        const article_checkboxes: NodeListOf<HTMLInputElement> = shadow_root.querySelectorAll('.articles input[type="checkbox"]');
        article_checkboxes.forEach((checkbox: HTMLInputElement): void => {
            if (checkbox.checked) {
                const price = parseFloat(checkbox.getAttribute('data-price') || '0');
                total += price;
            }
        });
        const total_element: HTMLElement | null = shadow_root.querySelector('.articles-total') as HTMLElement | null;
        if (total_element) {
            total_element.textContent = new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(total);
        }
    }

    const article_checkboxes: NodeListOf<HTMLInputElement> = shadow_root.querySelectorAll('.articles input[type="checkbox"]');
    article_checkboxes.forEach((checkbox: HTMLInputElement): void => {
        checkbox.addEventListener('change', update_total_price);
    });

    update_total_price();

    const checkin_input: HTMLInputElement = shadow_root.getElementById('checkin_input') as HTMLInputElement;
    const checkout_input: HTMLInputElement = shadow_root.getElementById('checkout_input') as HTMLInputElement;

    /**
     * Format a Date object into a YYYY-MM-DD string.
     *
     * @param date Date object to format.
     * @returns Formatted date string.
     */
    function format_date(date: Date): string {
        const year: number = date.getFullYear();
        const month: string = ('0' + (date.getMonth() + 1)).slice(-2);
        const day: string = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    // Set the checkin input's minimum date to today.
    const today: Date = new Date();
    const today_str: string = format_date(today);
    checkin_input.min = today_str;

    // Set checkout's initial minimum date to checkin + 1 day.
    const initial_checkin: Date = new Date(checkin_input.value || today_str);
    const initial_checkout: Date = new Date(initial_checkin);
    initial_checkout.setDate(initial_checkout.getDate() + 1);
    checkout_input.min = format_date(initial_checkout);

    // Listen for changes on the checkin input.
    checkin_input.addEventListener('change', (): void => {
        const checkin_val: string = checkin_input.value;
        if (!checkin_val) {
            return;
        }
        const new_checkin_date: Date = new Date(checkin_val);
        const new_checkout_date: Date = new Date(new_checkin_date);
        new_checkout_date.setDate(new_checkout_date.getDate() + 1);
        const new_checkout_str: string = format_date(new_checkout_date);

        // Update checkout's value and minimum.
        checkout_input.value = new_checkout_str;
        checkout_input.min = new_checkout_str;
    });

});
